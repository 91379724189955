var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.onLoadMore),expression:"onLoadMore"}],staticClass:"task-list",attrs:{"infinite-scroll-disabled":"disabledLoadingMore","infinite-scroll-distance":"50"}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"headers":_vm.headers}}),_c('app-table',{staticClass:"app-table-new v-data-table theme--light",attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"list":_vm.list,"item-key":"id","hide-default-footer":"","server-items-length":_vm.list.length,"single-select":false,"loading":_vm.loading,"show-select":false,"mobile-breakpoint":NaN,"sort-by":_vm.sortModel.by,"sort-desc":_vm.sortModel.desc,"hide-default-header":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){return _vm.$set(_vm.sortModel, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sortModel, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sortModel, "desc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sortModel, "desc", $event)},_vm.updateSorting]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var select = ref.select;
var isSelected = ref.isSelected;
var index = ref.index;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('item',{key:item.id,attrs:{"item":item,"isSelected":isSelected,"select":select,"index":index,"expand":expand,"isExpanded":isExpanded,"activeItems":_vm.activeItems},on:{"onGoToEdit":_vm.goToEdit,"change:status":function($event){return _vm.$emit('change:status', $event)},"needToUpdate":function($event){return _vm.$emit('needToUpdate')}}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pl-0 pr-0 app-table__expand",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"task-app-table"},[_vm._l((item.applications),function(application){return [_c('application-item',{key:application.application_id,attrs:{"app":application}})]})],2)])]}}]),model:{value:(_vm.activeItems),callback:function ($$v) {_vm.activeItems=$$v},expression:"activeItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }