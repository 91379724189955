<template lang="pug">
  .task-list(v-infinite-scroll="onLoadMore" infinite-scroll-disabled="disabledLoadingMore" infinite-scroll-distance="50")
    v-data-table(v-show="false" :headers="headers")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :expanded.sync="expanded"
      :list="list"
      item-key="id"
      hide-default-footer
      multi-sort=false
      :server-items-length="list.length"
      :single-select="false"
      :loading="loading"
      :show-select="false"
      :mobile-breakpoint='NaN'
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      hide-default-header
      class="app-table-new v-data-table theme--light"
    )
      template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
        item(
          :item="item"
          :isSelected="isSelected"
          :select="select"
          :index="index"
          :expand="expand"
          :isExpanded="isExpanded"
          :key="item.id"
          :activeItems="activeItems"
          @onGoToEdit="goToEdit"
          @change:status="$emit('change:status', $event)"
          @needToUpdate="$emit('needToUpdate')"
        )

      template(v-slot:expanded-item="{ headers, item }")
        td(:colspan="headers.length").pl-0.pr-0.app-table__expand
          div.task-app-table
            template(v-for="application in item.applications")
              application-item(:app="application" :key="application.application_id")
</template>

<script>
import InfinityScroll from '@/components/global/infinityScroll.vue'
import SortModel from "../core/taskAppSortModel"

export default {
  props: {
    list: Array,
    headers: Array,
    loading: Boolean,
    pagination: Object
  },

  data: () => ({
    activeItems: [],
    expanded: [],
    sortModel: null
  }),

  created() {
    this.initSortModel()
  },

  methods: {
    initSortModel() {
      this.sortModel = new SortModel(this.$route.query)
    },
    goToEdit({expand, isExpanded}) {
      expand(!isExpanded)
    },

    onLoadMore() {
      if (!this.pagination) return
      this.$emit('loadMore')
    },

    async updateSorting() {
      let data = this.sortModel.getApiData(this.$route.query)
      let ordering
      if (data.ordering.length) ordering = data.ordering
      this.$emit('change:sorting', ordering)
    },
  },

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false
    },
  },

  components: {
    infinityScroll: InfinityScroll,
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./TaskApplicationsItem.vue'),
    applicationItem: () => import('./TaskApplicationApp.vue')
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/tableNew";

.app-table-new .v-data-table tbody tr td:first-child {
  width: 32px;
  height: 32px;
}

.task-list {
  min-height: 100vh;
}

.task-applications-actions {
  width: 120px;
}

.task-app-table {
  display: table;
  width: 100%;
}
</style>
